import {inject, Injectable} from '@angular/core';
import {Storage} from "@ionic/storage-angular";
import {CanActivateFn, Router} from "@angular/router";
import {concatMap, from, Observable, of, throwError} from "rxjs";

const ACCESS_TOKEN_KEY = 'access-token';
const REFRESH_TOKEN_KEY = 'refresh-token';
const ROLES_KEY = 'role';


@Injectable({
  providedIn: 'root'
})
export class AuthStorageService {
  get tokenPermissions(): {admin: boolean} {
    return this._tokenPermissions;
  }
  get refreshToken(): string | null {
    return this._refreshToken;
  }
  get accessToken(): string | null {
    return this._accessToken;
  }

  async awaitingRepop() {
    console.log(await this.storage.get(ROLES_KEY));
    try {
      JSON.parse(await this.storage.get(ROLES_KEY))
      return false;
    } catch (_) {
      return true;
    }
  }

  private _accessToken: string | null = null;
  private _refreshToken: string | null = null;
  private _tokenPermissions: {admin: boolean} = {admin: false};

  constructor(private storage: Storage) {
    this.storage.create()
      .then(s => { this.storage = s; });
  }

  /**
   * @deprecated: will be made private shortly
    */
  public async load(): Promise<[string, string] | null>  {
    const tok = await this.storage.get(ACCESS_TOKEN_KEY);
    const ref = await this.storage.get(REFRESH_TOKEN_KEY);
    const rol = await this.storage.get(ROLES_KEY);
    let perm = {admin: false}
    try {
      perm = JSON.parse(rol)
    } catch (_) {
    }
    if (tok !== null && ref !== null) {
      this._accessToken = tok;
      this._refreshToken = ref;
      this._tokenPermissions = perm;
      if (this.accessToken !== null)
        return [this.accessToken, ref];
    }
    return null;
  };

  public loadAccessToken(): Observable<string> {
    return from(this.load())
      .pipe(
        concatMap(toks => {
          if (null === toks)
            return throwError(() => new Error('No access token available'));
          else
            return of(toks[0]);
        })
      )
  }

  public async store(tok: string, ref: string, permissions: {admin: boolean} | null = null): Promise<any> {
    const maybeTok = tok;

    if (maybeTok) {
      this._accessToken = maybeTok;
      this._refreshToken = ref;
      this._tokenPermissions = permissions ?? {admin: false};
      // const renew = new Date(this._accessToken.exp));
      return Promise.all([
        this.storage.set(ACCESS_TOKEN_KEY, tok),
        this.storage.set(REFRESH_TOKEN_KEY, ref),
        this.storage.set(ROLES_KEY, JSON.stringify(permissions)),
      ])
    }

    return Promise.reject(<string>maybeTok)
  }

  public updateAdmin(isAdmin: boolean) {
    this._tokenPermissions.admin = isAdmin;
    return this.storage.set(ROLES_KEY, JSON.stringify(this._tokenPermissions))
  }

  public async remove() {
    this._accessToken = null;
    return Promise.all([
      this.storage.remove(ACCESS_TOKEN_KEY),
      this.storage.remove(REFRESH_TOKEN_KEY),
    ]);
  }
}

// export const authConfig = (auth: JwtControlService): WVConfiguration => {
//   return new WVConfiguration({
//     basePath: 'https://api.ihookit.com',
//     credentials:
//       {JWT: () =>
//           auth?.accessToken?.raw
//             ? ('Bearer ' + auth.accessToken.raw)
//             : undefined
//       },
//   });
// }

const withJWT: ((logged: 'ensureLoggedIn' | 'ensureNotLoggedIn' | 'ensureAdmin') => CanActivateFn) =
  logged =>
    async (route, state) => {
      const router = inject(Router)
      const auth = inject(AuthStorageService)
      const tok = await auth.load();
      switch (logged) {
        case 'ensureLoggedIn':
          if (tok === null)
            return router.parseUrl('/login');
          return true;
        case 'ensureAdmin':
          if (tok === null)
            return router.parseUrl('/login');
          if (auth.tokenPermissions.admin)
            return true;
          return router.parseUrl('/home')
        case 'ensureNotLoggedIn':
          if (tok !== null)
            return router.parseUrl('/home');
          return true;
      }
    }
export const isLoggedIn = withJWT('ensureLoggedIn');
export const isLoggedOut = withJWT('ensureNotLoggedIn');
export const isLoggedAdmin = withJWT('ensureAdmin');
